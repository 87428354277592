import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Home from "../Home/Home";
import { useMsal } from "@azure/msal-react";
import { fetchUserPermission } from "../../components/SecurityComponent/SecurityComponent";
import { patchData } from "../../api/apiService";

const Agreement = () => {
  const { instance } = useMsal();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [eulaVersion, setEulaVersion] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const userData = await fetchUserPermission();
      const eulaVersion = userData.data.eulaVersion;
      const eulaAcceptedVersion = userData.data.user.eulaAcceptedVersion;
      setEulaVersion(eulaVersion);
      if (eulaAcceptedVersion === null || eulaAcceptedVersion < eulaVersion) {
        setShowModal(true);
      } else {
        if (userData?.dev_security_label?.includes("home_insight")) {
          navigate("/insights");
        } else if (userData?.dev_security_label?.includes("nav_pvp")) {
          navigate("/pvp");
        } else {
          navigate("/insights");
        }
      }
    } catch (error) {
      console.error("error", error);
    }
  }, [navigate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleOkClick = async () => {
    const userId = localStorage.getItem("user_id");
    if (!userId) {
      return;
    }

    try {
      var response = await patchData(`/users/${userId}/acceptEula`, {
        version: eulaVersion,
      });
      if (response.status === 200) {
        const userData = await fetchUserPermission();
        if (userData?.dev_security_label?.includes("home_insight")) {
          navigate("/insights");
        } else if (userData?.dev_security_label?.includes("nav_pvp")) {
          navigate("/pvp");
        } else {
          navigate("/insights");
        }
      } else {
        instance.logoutRedirect({ postLogoutRedirectUri: "/" });
      }
    } catch (error) {
      instance.logoutRedirect({ postLogoutRedirectUri: "/" });
    }
  };

  const handleCancelClick = () => {
    instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  };

  return (
    <>
      <style>
        {`
          .navbar-nav {
            display: none;
          }
        `}
      </style>
      <Home />
      <Modal
        show={showModal}
        size="lg"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>CareHub Application Agreement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            By clicking "Agree," you acknowledge that you have read, understood,
            and accepted the{" "}
            <Link
              to="/termsofuse"
              target="_blank"
              rel="noopener noreferrer"
              className="app-black link"
            >
              Terms of Use
            </Link>{" "}
            and the{" "}
            <Link
              to="/enduseragreement"
              target="_blank"
              rel="noopener noreferrer"
              className="app-black link"
            >
              End User License Agreement
            </Link>{" "}
            for the Carehub Application.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleOkClick}>
            Agree
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Agreement;
