import React, { useEffect, useState } from "react";
import { fetchData } from "../../../api/apiService";

const HealthPage = () => {
  const [healthStatus, setHealthStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHealth = async () => {
      setLoading(true);

      try {
        const fetchedData = await fetchData("/health");
        if (fetchedData.status === "ok") {
          setHealthStatus("200");
        } else {
          setHealthStatus("503");
        }
      } catch (error) {
        setHealthStatus("503");
      } finally {
        setLoading(false);
      }
    };

    fetchHealth();
  }, []);

  if (loading) {
    return <div></div>;
  }

  return <div>{healthStatus ? <pre>{healthStatus}</pre> : <div>503</div>}</div>;
};

export default HealthPage;
