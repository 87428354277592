import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import footerLogo from "../../../src/assets/images/footer_logo.png";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer py-3">
      <Container className="container white-bg">
        <Row>
          <Col xs={12} md={8}>
            <div className="text-start">
              <ul className="list-unstyled">
                <li>
                  <span className="app-black">
                    &#169; Copyright {currentYear} - Honest Medical Group - All
                    Rights Reserved.
                  </span>
                </li>
                <li>
                  <Link
                    to="https://honestmedicalgroup.com/policies/privacy/"
                    target="_blank"
                    className="app-black link"
                  >
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="text-end">
              <ul className="list-unstyled">
                <li className="hide">
                  <span className="app-grey">Powered By Honest CareHub</span>
                </li>
                <li>
                  <Link
                    to="https://honestmedicalgroup.com/"
                    target="_blank"
                    className="app-black link"
                  >
                    <Image src={footerLogo} className="mb-2" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.facebook.com/honestmedicalgroup"
                    target="_blank"
                    className="app-grey link"
                  >
                    <FaFacebookF className="fs-4"></FaFacebookF>
                  </Link>
                  <Link
                    to="https://twitter.com/HonestMedGroup"
                    target="_blank"
                    className="app-grey link"
                  >
                    <FaXTwitter className="fs-4"></FaXTwitter>
                  </Link>
                  <Link
                    to="https://www.instagram.com/honestmedgroup"
                    target="_blank"
                    className="app-grey link"
                  >
                    <FaInstagram className="fs-4"></FaInstagram>
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/honest-medical-group"
                    target="_blank"
                    className="app-grey link"
                  >
                    <FaLinkedinIn className="fs-4"></FaLinkedinIn>
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
