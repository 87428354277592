import axios from "axios";
import { API_URL } from "../config/app.constant";

const checkToken = () => {
  const token = localStorage.getItem("accessToken");
  if (!token || token === "undefined") {
    window.location.href = "/";
    return null;
  }
  return token;
};

export const fetchData = async (url) => {
  const token = checkToken();
  try {
    if (!token) return;

    const response = await axios.get(`${API_URL}${url}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      timeout: 10000,
    });
    return response.data;
  } catch (error) {
    if (!token) return;
    throw error.response ? error.response.data : error.message;
  }
};

export const patchData = async (url, body = {}) => {
  const token = checkToken();
  try {
    if (!token) return;

    const response = await axios.patch(`${API_URL}${url}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      timeout: 10000,
    });
    return response;
  } catch (error) {
    if (!token) return;
    throw error.response ? error.response.data : error.message;
  }
};

export const postData = async (url, data) => {
  const token = checkToken();
  try {
    if (!token) return;

    const response = await axios.post(`${API_URL}${url}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      timeout: 10000,
    });
    return response.data;
  } catch (error) {
    if (!token) return;
    throw error.response ? error.response.data : error.message;
  }
};

export const postMultipartData = async (url, formData) => {
  const token = checkToken();
  try {
    if (!token) return;

    const response = await axios.post(`${API_URL}${url}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      timeout: 50000,
    });
    return response;
  } catch (error) {
    if (!token) return;
    throw error.response ? error.response.data : error.message;
  }
};
