import React, { useState, useEffect } from 'react';
import { fetchData, postData } from '../../../api/apiService';
import RoleManagement from "../../../components/RoleManagement/RoleManagement";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for react-toastify

const RoleManagementPage = () => {
    const [roles, setRoles] = useState([]);
    const [components, setComponents] = useState([]);
    // const [loading, setLoading] = useState(false);
    // const [error, setError] = useState(null);

    useEffect(() => {
        const fetchRoles = async () => {
            // setLoading(true);
            try {
                const fetchedData = await fetchData('/roles?isReturnAll=true');
                setRoles(fetchedData.roles);
            } catch (error) {
                // setError(error);
            }
            // setLoading(false);
        };
        const fetchComponents = async () => {
            // setLoading(true);
            try {
                const fetchedData = await fetchData('/components');
                setComponents(fetchedData);
            } catch (error) {
                // setError(error);
            }
            // setLoading(false);
        };

        fetchRoles();
        fetchComponents();
    }, []);

    return (
        <>
            <RoleManagement roles={roles} components={components}  />
            <ToastContainer /> {/* Add ToastContainer to your component tree */}
        </>
    );
}

export default RoleManagementPage;
