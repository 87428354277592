import { Container } from "react-bootstrap";
import React from "react";

const Contact = () => {
  return (
    <Container className="border border-light rounded px-5 mt-2">
      <h3>Contact</h3>
    </Container>
  );
};

export default Contact;
