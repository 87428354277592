import React from 'react';
import { useMsal } from "@azure/msal-react";
import useSecurityErrorCheck from "../../../hooks/useSecurityErrorCheck";

const Error401Page = () => {
  const { instance } = useMsal();
  useSecurityErrorCheck(instance);
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>403 Forbidden</h1>
      <p style={styles.text}>Oops! You do not have permission to access this page.</p>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '100px',
  },
  heading: {
    fontSize: '36px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  text: {
    fontSize: '18px',
    marginBottom: '10px',
  }
};

export default Error401Page;
