import React from "react";
import { useMsal } from "@azure/msal-react";
import useSecurityErrorCheck from "../../../hooks/useSecurityErrorCheck";

const Error404Page = () => {
  const { instance } = useMsal();
  useSecurityErrorCheck(instance);
  return (
    <>
      <div style={styles.container}>
        <h1 style={styles.heading}>404 - Not Found</h1>
        <p style={styles.text}>The page you are looking for does not exist.</p>
      </div>
    </>
  );
};

const styles = {
  container: {
    textAlign: "center",
    marginTop: "100px",
  },
  heading: {
    fontSize: "36px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  text: {
    fontSize: "18px",
    marginBottom: "10px",
  },
};

export default Error404Page;
