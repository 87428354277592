// import AWS from "aws-sdk";
import * as CryptoJS from "crypto-js";
import {
  AZURE_APP_CLIENT_ID,
  AZURE_SCOPE_URL,
  AZURE_SIGNIN_POLICY,
  AZURE_PROFILE_EDIT_POLICY,
  AZURE_SIGNIN_AUTHORITY,
  AZURE_PROFILE_EDIT_AUTHORITY,
  AZURE_AUTHORITY_DOMAIN,
} from "../config/aws.constant";
import { API_URL } from "../config/app.constant";

const decrypt = async (cipherText) => {
  const bytes = CryptoJS.AES.decrypt(cipherText, "HonestCareHubGroup");
  return bytes.toString(CryptoJS.enc.Utf8);
};

const getAWSParameters = async () => {
  try {
    const encResult = await fetch(`${API_URL}/aws/parameters`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!encResult.ok) {
      throw new Error("Network response was not ok");
    }
    const text = await encResult.text();
    const response = await decrypt(text);
    return JSON.parse(response);
  } catch (error) {
    console.error("Error fetching parameters from AWS SSM:", error);
    throw error;
  }
};
const values = await getAWSParameters();

// eslint-disable-next-line
export default {
  azure: {
    clientId: values?.[AZURE_APP_CLIENT_ID],
    scopeUrl: values?.[AZURE_SCOPE_URL],
    signinPolicy: values?.[AZURE_SIGNIN_POLICY],
    profileEditPolicy: values?.[AZURE_PROFILE_EDIT_POLICY],
    signinAuthority: values?.[AZURE_SIGNIN_AUTHORITY],
    editAuthority: values?.[AZURE_PROFILE_EDIT_AUTHORITY],
    authorityDomain: values?.[AZURE_AUTHORITY_DOMAIN],
  },
};
