import {
  Container,
  Button,
  Form,
  Row,
  Col,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./RoleManagement.css";
import Loader from "../../ui/Layout/Loader";
import { Navigate } from "react-router-dom";
import { fetchUserPermission } from "../../components/SecurityComponent/SecurityComponent";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { fetchData, postData } from "../../api/apiService";
import { FaEye } from "react-icons/fa";

const RoleManagement = ({ components }) => {
  const [roles, setRoles] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);
  const [formData, setFormData] = useState({ name: "" });
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [allComponents, setAllComponents] = useState([]);
  const [savedCheckedItems, setSavedCheckedItems] = useState({});
  const [activeTab, setActiveTab] = useState("role_management");
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [nextTab, setNextTab] = useState("role_management");
  const roleNameRef = useRef(null);

  useEffect(() => {
    const fetchDataPermissions = async () => {
      try {
        const userPermission = await fetchUserPermission();
        setPermissions(userPermission.dev_security_label);
        setPermissionsLoaded(true);
      } catch (error) {
        if (error.statusCode === 401) {
          instance.logoutRedirect({ postLogoutRedirectUri: "/" });
        }
        if (error.statusCode === 403) {
          navigate("/403");
        }
      }
    };
    fetchDataPermissions();
  }, [instance, navigate]);

  useEffect(() => {
    const fetchAllComponents = async () => {
      try {
        const result = await fetchData(`/components`);
        setAllComponents(result.components);
      } catch (error) {
        console.error("Error fetching all components:", error);
      }
    };
    fetchAllComponents();
  }, []);

  const loadRoles = async () => {
    try {
      const fetchedData = await fetchData("/roles?isReturnAll=true");
      setRoles(fetchedData.roles);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  useEffect(() => {
    loadRoles();
  }, []);

  useEffect(() => {
    if (selectedRoleId) {
      const fetchComponents = async () => {
        try {
          const result = await fetchData(`/roles/${selectedRoleId}/components`);
          const newCheckedItems = {};
          result.components.forEach((component) => {
            newCheckedItems[component.id] = true;
          });
          setCheckedItems(newCheckedItems);
          setSavedCheckedItems(newCheckedItems);
        } catch (error) {
          console.error("Error fetching role components:", error);
        }
      };
      fetchComponents();
    }
  }, [selectedRoleId]);

  if (!permissionsLoaded) {
    return <Loader />;
  }

  const toggleCheckbox = (id) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [id]: !prevCheckedItems[id],
    }));
  };

  const handleSubmitPermission = async () => {
    const selectedCheckboxes = Object.keys(checkedItems)
      .filter((key) => checkedItems[key])
      .map(Number);

    try {
      await postData(`/roles/${selectedRoleId}/assignPermissions`, {
        componentIds: selectedCheckboxes,
      });
      toast.success("Permissions updated successfully.");
      setSavedCheckedItems(checkedItems);
    } catch (error) {
      console.error("Error assigning permissions:", error);
      toast.error("Failed to update permissions.");
    }
  };

  const handleFormSubmit = async (createAnother) => {
    if (!formData.name.trim()) {
      toast.error("Role name is required.");
      return;
    }

    formData.createdBy = 1;
    formData.updatedBy = 1;
    try {
      const postResult = await postData("/roles", formData);
      toast.success("Role created successfully!");
      await loadRoles();

      if (!createAnother) {
        setSelectedRoleId(postResult.id);
        setTimeout(() => setActiveTab("role_management"), 2000);
      } else {
        setFormData({ name: "" });
        if (roleNameRef.current) {
          roleNameRef.current.focus();
        }
      }
    } catch (error) {
      const errorMessage =
        error.statusCode === 409
          ? error.message
          : "An error occurred. Please try again.";
      toast.error(errorMessage);
    }
  };

  const handleRoleChange = (event) => {
    if (isModified) {
      const confirmLeave = window.confirm(
        "You have unsaved changes. Do you want to discard them and proceed?"
      );
      if (!confirmLeave) return;
    }
    const selectedId = event.target.value;
    setSelectedRoleId(selectedId);
  };

  const renderCheckboxes = (parentItem, parentLabel, level = 0) => {
    const hasMultipleChildren =
      parentItem.children && parentItem.children.length > 1;
    const containerClass = hasMultipleChildren
      ? "horizontal-checkbox-container"
      : "";

    return (
      <Form.Group key={parentLabel}>
        <div
          className={`ml-${level * 3} ${containerClass}`}
          style={{ marginTop: level * 3 }}
        >
          {parentItem.children.map((child, index) => (
            <React.Fragment key={`${parentLabel}-${child.label}-${index}`}>
              <div style={{ marginLeft: level * 20 }}>
                {Array(level * 2)
                  .fill(" ")
                  .join("")}
                <Form.Check
                  type="checkbox"
                  id={`${parentLabel}-${child.label}-${index}`}
                  label={
                    <span>
                      {child.label}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-${child.label}-${index}`}>
                            {child.description || "No description available"}{" "}
                            {/* Fallback if description is missing */}
                          </Tooltip>
                        }
                        container={document.body} // Ensures the tooltip is rendered in the body
                        delay={{ show: 250, hide: 400 }} // Optional: Add a delay for smoother appearance
                      >
                        <span>
                          <FaEye className="ml-1" />
                        </span>
                      </OverlayTrigger>
                    </span>
                  }
                  checked={checkedItems[child.id] || false}
                  onChange={() => toggleCheckbox(child.id)}
                  className={`nested-checkbox ${
                    hasMultipleChildren ? "horizontal-checkbox" : ""
                  }`}
                />
              </div>
              {child.children &&
                renderCheckboxes(
                  child,
                  `${parentLabel}-${child.label}`,
                  level + 1
                )}
            </React.Fragment>
          ))}
        </div>
      </Form.Group>
    );
  };

  const generateHierarchy = (data, parentId = null) => {
    if (!data || !Array.isArray(data)) {
      console.error("Invalid data provided. Expected an array.");
      return [];
    }

    return data
      .filter((item) => item.parentId === parentId)
      .map((item) => {
        const children = generateHierarchy(data, item.id);
        return {
          id: item.id,
          label: item.name,
          description: item.description,
          children: children.length ? children : undefined,
        };
      });
  };

  const parentCheckbox = {
    label: "Parent",
    children: generateHierarchy(allComponents),
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const isModified =
    JSON.stringify(checkedItems) !== JSON.stringify(savedCheckedItems);
  const isFormValid = formData.name.trim() !== "";

  const handleTabChange = (tab) => {
    if (tab === "create_role" && isModified) {
      setNextTab(tab);
      setShowWarningModal(true); // Show modal if there are unsaved changes
    } else {
      setActiveTab(tab);
    }
  };

  const handleModalCancel = () => {
    setShowWarningModal(false); // Close modal without changing tabs
  };

  const handleModalContinue = () => {
    setShowWarningModal(false); // Close modal and switch to the new tab
    setActiveTab(nextTab);
  };

  return (
    <>
      {permissions?.includes("home_role_management") ? (
        <Container className="border border-light rounded px-5 mt-2">
          <h3>Role Management</h3>
          <Row className="m-4">
            <Tabs
              activeKey={activeTab}
              onSelect={(tab) => handleTabChange(tab)}
              id="role-management-tabs"
              className="mb-3"
            >
              <Tab eventKey="role_management" title="Role Management">
                <Form>
                  <Row>
                    <Col xs={12} md={6} lg={4}>
                      <Form.Group controlId="configName">
                        <Form.Label>Role</Form.Label>
                        <Form.Select
                          onChange={handleRoleChange}
                          value={selectedRoleId}
                        >
                          <option value="">Select Role</option>
                          {roles.map((option, index) => (
                            <option key={index} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={8}>
                      <Form.Group>
                        <Form.Label as="legend" column sm={2}>
                          &nbsp;
                        </Form.Label>
                        <Col sm={10}>
                          {renderCheckboxes(parentCheckbox, "Parent")}
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setCheckedItems(savedCheckedItems);
                        toast.info(
                          "Permissions have been reset to the previously saved values."
                        );
                      }}
                      disabled={!isModified}
                    >
                      Reset
                    </Button>
                    <Button
                      className="primary-btn"
                      type="button"
                      onClick={handleSubmitPermission}
                      disabled={!isModified}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </Tab>
              <Tab eventKey="create_role" title="Create Role">
                <Form>
                  <Row>
                    <Col xs={12} md={6} lg={4}>
                      <Form.Group controlId="configName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Role Name"
                          name="name"
                          value={formData.name}
                          ref={roleNameRef}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4">
                    <Button
                      variant="secondary"
                      onClick={() => setFormData({ name: "" })}
                    >
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => handleFormSubmit(false)}
                      disabled={!isFormValid}
                    >
                      Add
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => handleFormSubmit(true)}
                      disabled={!isFormValid}
                    >
                      Add and Create Another
                    </Button>
                  </div>
                </Form>
              </Tab>
            </Tabs>
          </Row>
          <ToastContainer />
          <Modal show={showWarningModal} onHide={handleModalCancel}>
            <Modal.Header closeButton>
              <Modal.Title>Unsaved Changes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              You have unsaved changes. Do you want to discard them and proceed?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalCancel}>
                No, Stay
              </Button>
              <Button variant="primary" onClick={handleModalContinue}>
                Yes, Discard Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      ) : (
        <Navigate to="/401" replace />
      )}
    </>
  );
};

export default RoleManagement;
