// import { LogLevel } from "@azure/msal-browser";
import config from "./config/config";
const { azure } = config;

const apiConfig = {
  b2cScopes: [azure.scopeUrl],
};

const b2cPolicies = {
  names: {
    signUpSignIn: azure.signinPolicy,
    editProfile: azure.profileEditPolicy,
  },
  authorities: {
    signUpSignIn: {
      authority: azure.signinAuthority,
    },
    editProfile: {
      authority: azure.editAuthority,
    },
  },
  authorityDomain: azure.authorityDomain,
};

export const msalConfig = {
  auth: {
    clientId: azure.clientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: window.location.href,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        // switch (level) {
        //   case LogLevel.Error:
        //     console.error(message);
        //     return;
        //   case LogLevel.Info:
        //     console.info(message);
        //     return;
        //   case LogLevel.Verbose:
        //     console.debug(message);
        //     return;
        //   case LogLevel.Warning:
        //     console.warn(message);
        //     return;
        //   default:
        //     return;
        // }
      },
    },
  },
};

export const loginRequest = {
  scopes: ["openid", ...apiConfig.b2cScopes],
};

export const tokenRequest = {
  scopes: [...apiConfig.b2cScopes],
  forceRefresh: false,
};
