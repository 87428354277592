import { fetchData } from "../../api/apiService";

export const fetchUserPermission = async () => {
  try {
    // if (
    //   process.env.NODE_ENV === "development" ||
    //   !localStorage.getItem("label")
    // ) {
      const fetchedData = await fetchData("/users/permissions");
      let componentIds = fetchedData.componentIds;
      let components = fetchedData.components;
      let permissions = [];
      localStorage.setItem("user_name", fetchedData.user.name);
      localStorage.setItem("user_email", fetchedData.user.email);
      localStorage.setItem("user_id", fetchedData.user.id);
      const getComponentHierarchy = (componentIds, components) => {
        const componentMap = new Map();
        components.forEach((component) =>
          componentMap.set(component.id, component)
        );

        const result = new Set();

        const addComponentAndParents = (id) => {
          if (result.has(id)) return;
          const component = componentMap.get(id);
          if (component) {
            result.add(component.id);
            if (component.parentId !== null) {
              addComponentAndParents(component.parentId);
            }
          }
        };

        componentIds.forEach((id) => addComponentAndParents(id));

        return Array.from(result).map((id) => componentMap.get(id));
      };

      // Get the permissions based on the component hierarchy
      permissions = getComponentHierarchy(componentIds, components);
      let dev_security_label = permissions.map((item) => item.name);
      localStorage.setItem("label", JSON.stringify(dev_security_label));
      return {
        data: fetchedData,
        dev_security_label: dev_security_label,
      };
    // } else {
    //   let dev_security_label = JSON.parse(localStorage.getItem("label"));
    //   return { dev_security_label: dev_security_label };
    // }
  } catch (error) {
    // console.error('Error fetching user data:', error);
    throw error; // Rethrow the error to handle it in the calling component
  }
};

export const fetchUserData = async () => {
  try {
    const fetchedData = await fetchData("/users/permissions");
    return fetchedData;
  } catch (error) {
    // console.error('Error fetching user data:', error);
    throw error; // Rethrow the error to handle it in the calling component
  }
};
