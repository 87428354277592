// import pino from "pino";

// Create a Pino logger instance
// const logger = pino();

// Utility function to log messages at different levels
const logMessage = (level, message, data = {}) => {
  // if (localStorage.getItem("user_email")) {
  //   const userObject = {
  //     user_email: localStorage.getItem("user_email"),
  //     accessToken: localStorage.getItem("accessToken"),
  //     user_name: localStorage.getItem("user_name"),
  //   };
  //   data.userData = userObject;

  //   switch (level) {
  //     case "trace":
  //       logger.trace({ message, ...data });
  //       break;
  //     case "debug":
  //       logger.debug({ message, ...data });
  //       break;
  //     case "info":
  //       logger.info({ message, ...data });
  //       break;
  //     case "warn":
  //       logger.warn({ message, ...data });
  //       break;
  //     case "error":
  //       logger.error({ message, ...data });
  //       break;
  //     case "fatal":
  //       logger.fatal({ message, ...data });
  //       break;
  //     default:
  //       throw new Error(`Invalid log level: ${level}`);
  //   }
  // }
};

export default logMessage;
