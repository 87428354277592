// authUtil.js

import { msalInstance } from "./index";

export async function acquireTokenSilent() {
  // Implementation of acquireTokenSilent function
}

export async function acquireTokenInteractive() {
  try {
    await msalInstance.loginRedirect(); // Redirect the user to the login page
  } catch (error) {
    console.error("Failed to initiate interactive login:", error);
  }
}
