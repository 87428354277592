import { useEffect } from "react";
import { patchData } from "../api/apiService";

const useSecurityErrorCheck = (instance) => {
  useEffect(() => {
    const reportUserError = async () => {
      const userId = localStorage.getItem("user_id");
      if (!userId) {
        return;
      }

      try {
        const response = await patchData(`/users/${userId}/errorAccess`);

        if (response.data.statusCode === 403) {
          localStorage.clear();
          instance.logoutRedirect({ postLogoutRedirectUri: "/" });
        }
      } catch (error) {
        localStorage.clear();
        instance.logoutRedirect({ postLogoutRedirectUri: "/" });
        // console.error("Error reporting user error:", error);
      }
    };

    const userId = localStorage.getItem("user_id");
    if (userId) {
      reportUserError();
    }
  }, [instance]);
};

export default useSecurityErrorCheck;
