import { useEffect } from "react";
// import { useState, useEffect } from "react";
// import Carousel from "react-bootstrap/Carousel";
// import Image from "react-bootstrap/Image";
import firstSliderImage from "../../../src/assets/images/slider/home_slider.png";
// import secondSliderImage from "../../../src/assets/images/slider/slider_2.jpg";
// import thirdSliderImage from "../../../src/assets/images/slider/slider_3.jpg";
import { LOG_MESSAGE } from "../../config/app.constant";
import logMessage from "../../../src/utils/logger";

const Home = () => {
  // const [index, setIndex] = useState(0);

  // const handleSelect = (selectedIndex) => {
  //   setIndex(selectedIndex);
  // };

  useEffect(() => {
    logMessage("info", LOG_MESSAGE.LOAD_HOME, {});
  });

  return (
    <div className="home-homepage">
      <div className="home-background-image">
        <img src={firstSliderImage} alt="Background" />

        <div className="home-text-overlay">
          <h1 className="home-title">Welcome to CareHub from Honest</h1>
          <h2 className="home-subtitle">
            Partner Portal for Actionable Insights
          </h2>
          <p className="home-message">
            <strong>CareHub from Honest</strong> delivers a suite of reports and
            resources, giving physicians and practices real-time access to
            actionable insights. From the cost of care overview to practice
            performance metrics, our platform is designed to support informed
            decision-making and complement your practice management. We invite
            you to get started with our user-friendly portal today.
          </p>
        </div>
      </div>
    </div>
    // <Carousel activeIndex={index} onSelect={handleSelect}>
    //   <Carousel.Item>
    //     <Image
    //       className="d-block w-100 carousel-image"
    //       src={firstSliderImage}
    //       // width="800"
    //       height="600"
    //       alt="News & Insights"
    //     />
    //     <Carousel.Caption className="carousel-caption">
    //       <h1>Welcome to CareHub from Honest</h1>
    //       <h3>Partner Portal for Actionable Insights</h3>
    //       <h5>
    //         <strong>CareHub from Honest</strong> delivers a suite of reports and
    //         resources, giving physicians and practices real-time access to
    //         actionable insights. From the cost of care overview to practice
    //         performance metrics, our platform is designed to support informed
    //         decision-making and complement your practice management. We invite
    //         you to get started with our user-friendly portal today.
    //       </h5>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   {/* <Carousel.Item>
    //     <Image
    //       className="d-block w-100"
    //       src={secondSliderImage}
    //       // width="800"
    //       height="525"
    //       alt="Second Slide"
    //     />
    //     <Carousel.Caption>
    //       <h1>Second Slide</h1>
    //       <h3>An Honest Perspective on Health Care</h3>
    //       <h5>
    //         We are exploring the transformative impact of value-based care on
    //         the health care system, sharing success stories, and discussing the
    //         challenges and solutions that lie ahead.
    //       </h5>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <Image
    //       className="d-block w-100"
    //       src={thirdSliderImage}
    //       // width="800"
    //       height="525"
    //       alt="Third Slide"
    //     />
    //     <Carousel.Caption>
    //       <h1>Third Slide</h1>
    //       <h3>An Honest Perspective on Health Care</h3>
    //       <h5>
    //         We are exploring the transformative impact of value-based care on
    //         the health care system, sharing success stories, and discussing the
    //         challenges and solutions that lie ahead.
    //       </h5>
    //     </Carousel.Caption>
    //   </Carousel.Item> */}
    // </Carousel>
  );
};

export default Home;
